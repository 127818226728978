import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, LineChart, Line } from 'recharts';
import { Book, Brain, CheckCircle, Clock, Map, Repeat, Target, Zap, Check, X, Award, Flame } from 'lucide-react';
import { Alert } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './components/ui/card';
import { Progress } from './components/ui/progress';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './components/ui/tabs';

export const levels = [
  { name: "Destruir Distrações", icon: Target, color: "#e74c3c" },
  { name: "Desconstruir", icon: Brain, color: "#3498db" },
  { name: "Regra 80/20", icon: CheckCircle, color: "#2ecc71" },
  { name: "Estilo de Aprendizagem", icon: Book, color: "#f39c12" },
  { name: "Estratégias para Aprender", icon: Zap, color: "#9b59b6" },
  { name: "7 Princípios para Aprender + Rápido", icon: Clock, color: "#34495e" },
  { name: "Períodos Regulares", icon: Repeat, color: "#e67e22" },
  { name: "Repetição", icon: Repeat, color: "#95a5a6" },
  { name: "Mapas Mentais", icon: Map, color: "#1abc9c" },
  { name: "Modelagem de Excelência", icon: Brain, color: "#3498db" }
];

const quizzes = [
  {
    category: "Destruir Distrações",
    questions: [
      {
        question: "Qual técnica é mais eficaz para manter o foco durante o estudo?",
        options: [
          "Multitasking constante",
          "Técnica Pomodoro (períodos focados com pausas)",
          "Estudar em ambientes barulhentos",
          "Manter notificações ativas"
        ],
        correct: 1,
        explanation: "A técnica Pomodoro, que envolve períodos focados de estudo intercalados com pausas curtas, é altamente eficaz para manter a concentração e evitar distrações. Ela ajuda a estruturar o tempo de estudo e a manter o foco por períodos gerenciáveis."
      },
      {
        question: "Qual é o impacto das redes sociais na produtividade do estudo?",
        options: [
          "Aumentam a concentração",
          "Não têm impacto significativo",
          "Reduzem significativamente o foco e a produtividade",
          "Melhoram a retenção de informações"
        ],
        correct: 2,
        explanation: "As redes sociais são uma das principais fontes de distração moderna, reduzindo significativamente o foco e a produtividade. Estudos mostram que mesmo breves interrupções para checar notificações podem quebrar o fluxo de concentração e diminuir a eficácia do estudo."
      },
      {
        question: "Como o ambiente físico afeta a capacidade de concentração?",
        options: [
          "Não tem influência na concentração",
          "Apenas a iluminação é importante",
          "Um ambiente organizado e silencioso melhora significativamente o foco",
          "Barulho constante ajuda a manter o alerta"
        ],
        correct: 2,
        explanation: "Um ambiente de estudo organizado e silencioso é crucial para melhorar a concentração. Elementos como iluminação adequada, ausência de desordem e controle de ruído contribuem significativamente para criar um espaço propício ao foco e aprendizado eficaz."
      },
      {
        question: "Qual estratégia é mais efetiva para gerenciar o uso do smartphone durante o estudo?",
        options: [
          "Manter o smartphone sempre próximo",
          "Usar o modo 'Não Perturbe' ou similar",
          "Checar notificações a cada 5 minutos",
          "Responder mensagens imediatamente"
        ],
        correct: 1,
        explanation: "Utilizar o modo 'Não Perturbe' ou similar é uma estratégia eficaz para gerenciar as distrações do smartphone. Isso permite que você mantenha o foco no estudo, eliminando interrupções de notificações, mas ainda mantendo a possibilidade de acesso em caso de emergência."
      },
      {
        question: "Como a gestão eficaz do tempo se relaciona com a eliminação de distrações?",
        options: [
          "Não há relação direta",
          "Aumenta as distrações",
          "Ajuda a criar períodos dedicados de foco, reduzindo oportunidades para distrações",
          "Diminui a produtividade geral"
        ],
        correct: 2,
        explanation: "A gestão eficaz do tempo está diretamente relacionada à eliminação de distrações. Ao planejar e estruturar o tempo de estudo, você cria períodos dedicados de foco, reduzindo naturalmente as oportunidades para distrações e aumentando a produtividade e eficácia do aprendizado."
      }
    ]
  },
  {
    category: "Desconstruir",
    questions: [
      {
        question: "O que significa 'desconstruir' em um contexto de aprendizado?",
        options: [
          "Construir novos hábitos rapidamente",
          "Desmontar conceitos em partes menores e mais compreensíveis",
          "Ignorar informações complexas",
          "Repetir conteúdos sem entender"
        ],
        correct: 1,
        explanation: "Desconstruir significa desmontar conceitos complexos em partes menores e mais compreensíveis para facilitar o entendimento e a retenção de informações."
      },
      {
        question: "Qual é o benefício de desconstruir problemas complexos?",
        options: [
          "Aumenta a procrastinação",
          "Permite entender e solucionar problemas mais rapidamente",
          "Elimina a necessidade de estudo",
          "Reduz a capacidade de concentração"
        ],
        correct: 1,
        explanation: "Desconstruir problemas complexos permite abordá-los de maneira mais simples e eficiente, facilitando a solução e a compreensão."
      },
      {
        question: "Como a desconstrução pode ser aplicada ao aprendizado de habilidades novas?",
        options: [
          "Estudando tudo de uma vez",
          "Focando apenas em teorias",
          "Dividindo a habilidade em componentes essenciais para praticar separadamente",
          "Ignorando os fundamentos"
        ],
        correct: 2,
        explanation: "Ao desconstruir habilidades em componentes essenciais, você pode praticar cada parte separadamente, melhorando a compreensão e domínio de cada etapa."
      },
      {
        question: "Quais métodos de desconstrução são eficazes para aprender novas habilidades?",
        options: [
          "Técnica Pomodoro",
          "Método GTD (Getting Things Done)",
          "Regra dos Dois Minutos",
          "Matriz de Eisenhower"
        ],
        correct: 1,
        explanation: "O método GTD (Getting Things Done) é uma técnica de desconstrução que ajuda a gerenciar tarefas complexas, dividindo-as em ações concisas e priorizadas."
      }
    ]
  },
  {
    category: "Regra 80/20",
    questions: [
      {
        question: "O que é a Regra 80/20?",
        options: [
          "Um princípio que sugere que 80% dos resultados vêm de 20% dos esforços",
          "Uma técnica de estudo intensivo por 80 minutos e descanso por 20",
          "Uma regra de prioridade para as tarefas menos importantes",
          "Um método de concentração extrema"
        ],
        correct: 0,
        explanation: "A Regra 80/20, ou Princípio de Pareto, afirma que 80% dos resultados desejados vêm de 20% dos esforços aplicados, permitindo uma gestão de tempo e recursos mais eficaz."
      },
      {
        question: "Como aplicar a Regra 80/20 no estudo?",
        options: [
          "Focando nos tópicos menos importantes",
          "Concentrando-se nos tópicos e métodos que geram maior impacto no aprendizado",
          "Revisando todo o conteúdo uniformemente",
          "Estudando em sessões curtas e frequentes"
        ],
        correct: 1,
        explanation: "Aplicar a Regra 80/20 no estudo envolve identificar e concentrar esforços nos tópicos e métodos que têm o maior impacto no aprendizado."
      }
    ]
  },
  {
    category: "Estilo de Aprendizagem",
    questions: [
      {
        question: "Quais são os principais estilos de aprendizagem?",
        options: [
          "Visual, auditivo, leitura/escrita e cinestésico",
          "Passivo, ativo, reflexivo e agressivo",
          "Lento, rápido, médio e avançado",
          "Estático, dinâmico, teórico e prático"
        ],
        correct: 0,
        explanation: "Os principais estilos de aprendizagem são visual, auditivo, leitura/escrita e cinestésico, e cada pessoa pode ter uma combinação única de estilos."
      },
      {
        question: "Como descobrir seu estilo de aprendizagem dominante?",
        options: [
          "Através da tentativa e erro",
          "Completando questionários específicos ou observando quais métodos são mais eficazes para você",
          "Apenas perguntando a um amigo",
          "Não é possível descobrir"
        ],
        correct: 1,
        explanation: "Descobrir seu estilo de aprendizagem dominante pode ser feito através de questionários específicos ou observando quais métodos de estudo são mais eficazes para você."
      }
    ]
  },
  {
    category: "Estratégias para Aprender",
    questions: [
      {
        question: "Qual é uma das estratégias mais eficazes para melhorar a retenção de informações?",
        options: [
          "Leitura passiva repetida",
          "Prática distribuída ao longo do tempo",
          "Fazer um resumo uma única vez",
          "Depender apenas de aulas"
        ],
        correct: 1,
        explanation: "A prática distribuída, ou seja, espalhar o estudo ao longo do tempo, é altamente eficaz para melhorar a retenção de informações."
      },
      {
        question: "Qual estratégia ajuda a aprender novas informações de forma mais profunda?",
        options: [
          "Aprender tudo de uma vez",
          "Usar métodos de elaboração, como a explicação detalhada",
          "Evitar fazer perguntas",
          "Ignorar exemplos práticos"
        ],
        correct: 1,
        explanation: "Usar métodos de elaboração, como explicar os conceitos em suas próprias palavras ou fazer conexões com o que já se sabe, aprofunda o entendimento."
      }
    ]
  },
  {
    category: "7 Princípios para Aprender + Rápido",
    questions: [
      {
        question: "Qual princípio ajuda a acelerar o aprendizado?",
        options: [
          "Ignorar erros e focar apenas nos resultados",
          "Utilizar feedback rápido e frequente para ajustar o processo de aprendizado",
          "Focar apenas na quantidade de tempo estudado",
          "Estudar apenas uma vez e esperar reter todas as informações"
        ],
        correct: 1,
        explanation: "Utilizar feedback rápido e frequente permite ajustes contínuos no processo de aprendizado, acelerando a aquisição de novas informações."
      }
    ]
  },
  {
    category: "Períodos Regulares",
    questions: [
      {
        question: "Por que estabelecer períodos regulares de estudo é importante?",
        options: [
          "Reduz a consistência",
          "Ajuda a criar um hábito e melhora a retenção",
          "Cria mais trabalho",
          "Aumenta a probabilidade de procrastinação"
        ],
        correct: 1,
        explanation: "Estabelecer períodos regulares de estudo ajuda a criar um hábito consistente e melhora a retenção ao consolidar informações regularmente."
      },
      {
        question: "Como estabelecer períodos regulares de estudo?",
        options: [
          "Estudar de forma irregular",
          "Definir horários específicos e manter a consistência",
          "Estudar apenas quando está disponível",
          "Escolher horários aleatórios"
        ],
        correct: 1,
        explanation: "Definir horários específicos e manter a consistência é uma estratégia eficaz para estabelecer períodos regulares de estudo."
      }
    ]
  },
  {
    category: "Repetição",
    questions: [
      {
        question: "Qual é o papel da repetição no aprendizado?",
        options: [
          "Reduz o aprendizado a longo prazo",
          "Ajuda a reforçar a memória e a fixar informações",
          "Diminui o interesse pelo assunto",
          "É completamente desnecessária"
        ],
        correct: 1,
        explanation: "A repetição é fundamental para reforçar a memória e fixar informações, especialmente para a retenção a longo prazo."
      },
      {
        question: "Como a repetição pode ser aplicada ao aprendizado de uma nova habilidade?",
        options: [
          "Praticar a habilidade repetidamente até dominar",
          "Estudar apenas uma vez e esperar reter todas as informações",
          "Focar apenas em teorias",
          "Ignorar exemplos práticos"
        ],
        correct: 1,
        explanation: "Praticar a habilidade repetidamente até dominar é uma estratégia eficaz para aprender novas habilidades."
      }
    ]
  },
  {
    category: "Mapas Mentais",
    questions: [
      {
        question: "Como os mapas mentais ajudam no aprendizado?",
        options: [
          "Complicam a estrutura de estudo",
          "Ajudam a organizar informações visualmente e a conectar ideias",
          "São apenas uma ferramenta de desenho",
          "Servem apenas para alunos de artes"
        ],
        correct: 1,
        explanation: "Mapas mentais ajudam a organizar informações visualmente, facilitando a conexão de ideias e a memorização de conceitos complexos."
      },
      {
        question: "Qual é a importância de usar cores em mapas mentais?",
        options: [
          "Ajuda a organizar informações visualmente",
          "Aumenta a complexidade",
          "Só serve para desenhar",
          "Não é necessário usar cores"
        ],
        correct: 0,
        explanation: "Usar cores em mapas mentais ajuda a organizar informações visualmente, tornando o aprendizado mais visual e memorável."
      }
    ]
  },
  {
    category: "Modelagem de Excelência",
    questions: [
      {
        question: "O que é a modelagem de excelência?",
        options: [
          "Copiar exatamente o que os outros fazem",
          "Identificar e adotar estratégias e comportamentos de indivíduos de sucesso",
          "Ignorar o que outras pessoas fazem",
          "Criar métodos próprios sem referência"
        ],
        correct: 1,
        explanation: "A modelagem de excelência é a prática de identificar e adotar as estratégias e comportamentos de indivíduos que alcançaram sucesso em um campo específico."
      },
      {
        question: "Como a modelagem de excelência pode ser aplicada ao seu próprio aprendizado?",
        options: [
          "Identificando e imitando os melhores métodos e estratégias",
          "Estudando apenas o que você gosta",
          "Focar apenas em teorias",
          "Ignorar exemplos práticos"
        ],
        correct: 0,
        explanation: "Identificando e imitando os melhores métodos e estratégias, você pode aprimorar seu próprio aprendizado e acelerar seu progresso."
      }
    ]
  },
  {
    name: "Gerenciamento de Tempo",
    description: "Aprenda a gerenciar seu tempo de forma eficaz para maximizar a produtividade e minimizar as distrações.",
    questions: [
      {
        question: "Qual é a técnica de gerenciamento de tempo que envolve trabalhar por 25 minutos e depois fazer uma pausa curta?",
        options: [
          "Técnica Pomodoro",
          "Método GTD",
          "Regra dos Dois Minutos",
          "Matriz de Eisenhower"
        ],
        correct: 0,
        explanation: "A Técnica Pomodoro envolve trabalhar por 25 minutos e depois fazer uma pausa curta de 5 minutos. Após quatro 'pomodoros', você faz uma pausa mais longa de 15-30 minutos."
      },
      {
        question: "Qual é a principal vantagem de usar uma lista de tarefas?",
        options: [
          "Aumenta a procrastinação",
          "Ajuda a priorizar e organizar tarefas",
          "Reduz a necessidade de pausas",
          "Elimina a necessidade de planejamento"
        ],
        correct: 1,
        explanation: "Usar uma lista de tarefas ajuda a priorizar e organizar suas atividades, garantindo que você se concentre nas tarefas mais importantes e urgentes primeiro."
      },
      {
        question: "O que é a Matriz de Eisenhower?",
        options: [
          "Uma técnica de meditação",
          "Uma ferramenta para priorizar tarefas com base em urgência e importância",
          "Um método de estudo",
          "Uma técnica de brainstorming"
        ],
        correct: 1,
        explanation: "A Matriz de Eisenhower é uma ferramenta de gerenciamento de tempo que ajuda a priorizar tarefas com base em sua urgência e importância, dividindo-as em quatro quadrantes."
      },
      {
        question: "Qual é a melhor maneira de lidar com tarefas grandes e complexas?",
        options: [
          "Procrastinar até o último minuto",
          "Dividir em tarefas menores e gerenciáveis",
          "Fazer tudo de uma vez",
          "Ignorar a tarefa"
        ],
        correct: 1,
        explanation: "Dividir tarefas grandes e complexas em partes menores e mais gerenciáveis facilita o gerenciamento e a conclusão dessas tarefas, reduzindo a sobrecarga e aumentando a produtividade."
      },
      {
        question: "Qual é a importância de definir prazos realistas?",
        options: [
          "Aumenta a pressão e o estresse",
          "Ajuda a manter o foco e a motivação",
          "Reduz a necessidade de planejamento",
          "Elimina a necessidade de pausas"
        ],
        correct: 1,
        explanation: "Definir prazos realistas ajuda a manter o foco e a motivação, garantindo que você tenha tempo suficiente para concluir as tarefas sem se sentir sobrecarregado ou estressado."
      }
    ]
  }
];

const LearningJourney = () => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const [score, setScore] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [learningProgress, setLearningProgress] = useState(Array(levels.length).fill(0));
  const [streak, setStreak] = useState(0);
  const [lastCompletedDate, setLastCompletedDate] = useState(null);

  const startQuiz = (levelIndex) => {
    setCurrentLevel(levelIndex);
    setShowQuiz(true);
    setCurrentQuestion(0);
    setSelectedAnswer(null);
    setShowExplanation(false);
  };

  const handleAnswerSelect = (answerIndex) => {
    setSelectedAnswer(answerIndex);
    setShowExplanation(true);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer === quizzes[currentLevel].questions[currentQuestion].correct) {
      setScore(score + 10);
      const newProgress = [...learningProgress];
      newProgress[currentLevel] += 20; // Increase progress by 20% for each correct answer
      setLearningProgress(newProgress);
    }

    // Update streak
    const today = new Date().toDateString();
    if (lastCompletedDate !== today) {
      setStreak(streak + 1);
      setLastCompletedDate(today);
    }

    if (currentQuestion < quizzes[currentLevel].questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(null);
      setShowExplanation(false);
    } else {
      setShowQuiz(false);
      if (currentLevel < levels.length - 1) {
        setCurrentLevel(currentLevel + 1);
      }
    }
  };

  const LevelCard = ({ level, index }) => (
    <Card className="flex flex-col items-center p-4 hover:shadow-lg transition-shadow">
      <CardHeader>
        <CardTitle className="text-lg font-bold">{level.name}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <level.icon size={48} color={level.color} />
        <Progress value={learningProgress[index]} className="w-full mt-4" />
      </CardContent>
      <CardFooter>
        <Button onClick={() => startQuiz(index)} disabled={index > currentLevel}>
          {index === currentLevel ? "Iniciar" : index < currentLevel ? "Revisar" : "Bloqueado"}
        </Button>
      </CardFooter>
    </Card>
  );

  const QuizComponent = () => (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>{levels[currentLevel].name}</CardTitle>
        <CardDescription>Questão {currentQuestion + 1} de {quizzes[currentLevel].questions.length}</CardDescription>
      </CardHeader>
      <CardContent>
        <p className="text-lg font-semibold mb-4">{quizzes[currentLevel].questions[currentQuestion].question}</p>
        <div className="grid gap-2">
          {quizzes[currentLevel].questions[currentQuestion].options.map((option, index) => (
            <Button
              key={index}
              variant={selectedAnswer === index ? "secondary" : "outline"}
              className="justify-start relative"
              onClick={() => handleAnswerSelect(index)}
              disabled={showExplanation}
            >
              {option}
              {showExplanation && (
                <span className="absolute right-2">
                  {index === quizzes[currentLevel].questions[currentQuestion].correct ? (
                    <Check className="text-green-500" size={20} />
                  ) : selectedAnswer === index ? (
                    <X className="text-red-500" size={20} />
                  ) : null}
                </span>
              )}
            </Button>
          ))}
        </div>
        {showExplanation && (
          <Alert className="mt-4">
            <p className="font-semibold">Explicação</p>
            <p>
              {quizzes[currentLevel].questions[currentQuestion].explanation}
            </p>
          </Alert>
        )}
      </CardContent>
      <CardFooter>
        <Button onClick={handleNextQuestion} disabled={!showExplanation}>
          {currentQuestion < quizzes[currentLevel].questions.length - 1 ? "Próxima Pergunta" : "Finalizar"}
        </Button>
      </CardFooter>
    </Card>
  );

  const ProgressChart = () => {
    const data = levels.map((level, index) => ({
      name: level.name,
      progress: learningProgress[index],
    }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="progress" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const SummaryStatistics = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <Target className="mr-2" /> Progresso Geral
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-2xl font-bold">{Math.round(learningProgress.reduce((a, b) => a + b, 0) / levels.length)}%</p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <Award className="mr-2" /> Níveis Concluídos
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-2xl font-bold">{learningProgress.filter(progress => progress === 100).length}/{levels.length}</p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <Flame className="mr-2" /> Sequência Atual
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-2xl font-bold">{streak} dias</p>
        </CardContent>
      </Card>
    </div>
  );

  const SkillBalanceChart = () => {
    const data = levels.map((level, index) => ({
      subject: level.name,
      A: learningProgress[index],
      fullMark: 100,
    }));

    return (
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 100]} />
          <Radar name="Progresso" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>
    );
  };

  const TimelineChart = () => {
    const data = levels.map((level, index) => ({
      name: level.name,
      progress: learningProgress[index],
    }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="progress" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-8">Jornada de Aprendizagem Acelerada</h1>
      <Tabs defaultValue="course" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="course">Curso</TabsTrigger>
          <TabsTrigger value="progress">Progresso</TabsTrigger>
        </TabsList>
        <TabsContent value="course">
          {showQuiz ? (
            <QuizComponent />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {levels.map((level, index) => (
                <LevelCard key={index} level={level} index={index} />
              ))}
            </div>
          )}
        </TabsContent>
        <TabsContent value="progress">
          <Card>
            <CardHeader>
              <CardTitle>Seu Progresso</CardTitle>
              <CardDescription>Acompanhe seu avanço em cada nível</CardDescription>
            </CardHeader>
            <CardContent>
              <SummaryStatistics />
              <Tabs defaultValue="bar" className="w-full">
                <TabsList>
                  <TabsTrigger value="bar">Progresso por Nível</TabsTrigger>
                  <TabsTrigger value="radar">Equilíbrio de Habilidades</TabsTrigger>
                  <TabsTrigger value="timeline">Linha do Tempo</TabsTrigger>
                </TabsList>
                <TabsContent value="bar">
                  <ProgressChart />
                </TabsContent>
                <TabsContent value="radar">
                  <SkillBalanceChart />
                </TabsContent>
                <TabsContent value="timeline">
                  <TimelineChart />
                </TabsContent>
              </Tabs>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default LearningJourney;
