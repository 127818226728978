import React, { useState } from 'react';

const LearningPreferences = () => {
  const [preferences, setPreferences] = useState({
    learningStyle: 'Visual',
    studyTime: 'Morning',
    dailyGoal: 2
  });

  const handleChange = (e) => {
    setPreferences({ ...preferences, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold mb-4">Learning Preferences</h2>
      {/* Add form elements for learning preferences */}
    </div>
  );
};

export default LearningPreferences;
