import React from 'react';

const SkillLevels = () => {
  const skills = [
    { name: 'Memory Techniques', level: 75 },
    { name: 'Speed Reading', level: 60 },
    { name: 'Mind Mapping', level: 80 },
    { name: 'Critical Thinking', level: 70 }
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold mb-4">Skill Levels</h2>
      {skills.map(skill => (
        <div key={skill.name} className="mb-4">
          <p>{skill.name}</p>
          <div className="bg-gray-200 rounded-full h-2.5">
            <div className="bg-violet-600 h-2.5 rounded-full" style={{ width: `${skill.level}%` }}></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillLevels;
