import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import LearningJourney from './LearningJourney';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Register from './pages/Register';

function App() {
  const [user, setUser] = useState(null);

  const handleLogout = () => {
    setUser(null);
  };

  return (
    <Router>
      <div className="App">
        <Navbar user={user} onLogout={handleLogout} />
        <main className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route 
              path="/jornada" 
              element={user ? <LearningJourney /> : <Navigate to="/entrar" replace />} 
            />
            <Route 
              path="/perfil" 
              element={user ? <Profile /> : <Navigate to="/entrar" replace />} 
            />
            <Route path="/entrar" element={<Login setUser={setUser} />} />
            <Route path="/cadastrar" element={<Register setUser={setUser} />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;