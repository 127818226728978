import React from 'react';
import { Link } from 'react-router-dom';

const FeatureCard = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <div className="text-3xl text-violet-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Home = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Bem-vindo a Central do Conhecimento</h1>
        <p className="text-xl text-gray-600">Acelere sua aprendizagem e potencialize seu conhecimento</p>
      </header>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-center mb-8">Nossos Recursos</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <FeatureCard 
            title="Técnicas de Aprendizado Acelerado" 
            description="Domine métodos comprovados para aprender mais rápido e reter informações por mais tempo."
            icon="🚀"
          />
          <FeatureCard 
            title="Cursos Personalizados" 
            description="Experimente uma jornada de aprendizado adaptada ao seu estilo e ritmo únicos."
            icon="🎯"
          />
          <FeatureCard 
            title="Comunidade de Aprendizes" 
            description="Conecte-se com outros estudantes motivados e compartilhe conhecimentos."
            icon="👥"
          />
        </div>
      </section>

      <section className="bg-violet-100 rounded-lg p-8 mb-12">
        <h2 className="text-3xl font-semibold mb-4">Comece Sua Jornada Hoje</h2>
        <p className="text-lg mb-6">Desbloqueie seu potencial de aprendizado e alcance seus objetivos mais rapidamente.</p>
        {localStorage.getItem('isLoggedIn') ? (
          <Link to="/learning-journey" className="bg-violet-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-violet-700 transition duration-300">
            Ir para Jornada de Aprendizagem
          </Link>
        ) : (
          <Link to="/register" className="bg-violet-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-violet-700 transition duration-300">
            Cadastre-se Gratuitamente
          </Link>
        )}
      </section>

      <section>
        <h2 className="text-3xl font-semibold text-center mb-8">Por que escolher a Central do Conhecimento?</h2>
        <ul className="list-disc pl-6 space-y-4 text-lg text-gray-700">
          <li>Métodos de aprendizagem baseados em evidências científicas</li>
          <li>Acompanhamento personalizado do seu progresso</li>
          <li>Recursos interativos para melhorar a retenção de conhecimento</li>
          <li>Flexibilidade para aprender no seu próprio ritmo</li>
          <li>Acesso a uma comunidade de aprendizes motivados</li>
        </ul>
      </section>
    </div>
  );
};

export default Home;