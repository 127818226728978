import React from 'react';

const EnrolledCourses = () => {
  const courses = [
    { name: 'Accelerated Learning Techniques', progress: 75 },
    { name: 'Memory Enhancement', progress: 40 },
    { name: 'Speed Reading', progress: 90 }
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-8">
      <h2 className="text-2xl font-semibold mb-4">Enrolled Courses</h2>
      {courses.map(course => (
        <div key={course.name} className="mb-4 flex justify-between items-center">
          <div>
            <p className="font-semibold">{course.name}</p>
            <p>Progress: {course.progress}%</p>
          </div>
          <button className="bg-violet-600 text-white px-4 py-2 rounded">Continue</button>
        </div>
      ))}
    </div>
  );
};

export default EnrolledCourses;
