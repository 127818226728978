import React, { useState } from 'react';
import UserInfo from '../components/UserInfo';
import LearningPreferences from '../components/LearningPreferences';
import SkillLevels from '../components/SkillLevels';
import EnrolledCourses from '../components/EnrolledCourses';
import AccountSettings from '../components/AccountSettings';

const Profile = () => {
  const [user, setUser] = useState({
    fullName: 'John Doe',
    email: 'johndoe@example.com',
    location: 'New York, USA',
    occupation: 'Software Developer',
    bio: 'Passionate learner and tech enthusiast. Always eager to expand my knowledge and skills in various fields, especially in accelerated learning techniques and cognitive enhancement.',
    avatar: 'https://example.com/avatar.jpg',
  });

  const handleUserUpdate = (updatedUser) => {
    setUser({ ...user, ...updatedUser });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">User Profile</h1>
      
      <UserInfo user={user} onUpdate={handleUserUpdate} />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        <LearningPreferences />
        <SkillLevels />
      </div>
      
      <EnrolledCourses />
      
      <AccountSettings />
    </div>
  );
};

export default Profile;