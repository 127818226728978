import React, { useState } from 'react';

const AccountSettings = () => {
  const [settings, setSettings] = useState({
    password: '',
    confirmPassword: '',
    emailNotifications: true,
    pushNotifications: true,
    language: 'English'
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setSettings({ ...settings, [e.target.name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle settings update
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-8">
      <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
      <form onSubmit={handleSubmit}>
        {/* Add form elements for account settings */}
        <button type="submit" className="bg-violet-600 text-white px-4 py-2 rounded">Save Settings</button>
      </form>
    </div>
  );
};

export default AccountSettings;
