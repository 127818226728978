import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_NOCODB_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Determine which type of token we're using
const isJWT = process.env.REACT_APP_NOCODB_TOKEN_TYPE === 'JWT';
const tokenHeaderName = isJWT ? 'xc-auth' : 'xc-token';

// Add a request interceptor to include the token in every request
api.interceptors.request.use((config) => {
  const token = process.env.REACT_APP_NOCODB_API_TOKEN;
  if (token) {
    config.headers[tokenHeaderName] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const login = async (email, password) => {
  try {
    const response = await api.get(`/api/v2/tables/m4adq5djw5uaxji/records?where=(email,eq,${email})`);
    if (response.data.list.length > 0) {
      const user = response.data.list[0];
      // Here you should implement proper password checking
      // This is just a placeholder and is not secure
      if (user.password === password) {
        return user;
      }
    }
    throw new Error('Invalid credentials');
  } catch (error) {
    throw error;
  }
};

export const register = async (userData) => {
  try {
    // Log the API token (remove this in production)
    console.log('API Base URL:', process.env.REACT_APP_NOCODB_BASE_URL);
    console.log('Token Type:', process.env.REACT_APP_NOCODB_TOKEN_TYPE);
    console.log('Token Header:', tokenHeaderName);

    const response = await api.post('/api/v2/tables/m4adq5djw5uaxji/records', userData);
    return response.data;
} catch (error) {
    console.error('API error:', error.response ? error.response.data : error.message);
    if (error.response && error.response.status === 401) {
      throw new Error('Erro de autenticação. Por favor, verifique o token de API e o tipo de token.');
    }
    throw new Error(error.response ? error.response.data.msg : error.message);
  }
};

export const getUserProfile = async (userId) => {
  try {
    const response = await api.get(`/api/v2/tables/m4adq5djw5uaxji/records/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add more API calls as needed

export default api;